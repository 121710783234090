import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JsonDumpService {
  private jsonDataSubject = new BehaviorSubject<any>(null); // Initial value can be null
  public jsonData$: Observable<any> = this.jsonDataSubject.asObservable(); // Expose as observable

  setJsonData(data: any): void {
    this.jsonDataSubject.next(data); // Emit new data
  }

  getJsonData(): Observable<any> {
    return this.jsonData$; // Return the observable
  }
}
